import React, { useEffect, useState } from "react";
import "./section1.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const Section1 = () => {
  const [info, setInfo] = useState([]);
  const [formName, setFormName] = useState("");
  const [forms, setForms] = useState([]);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${SERVER_URL}/career/sectionOne/list`
        );
        if (response.data && response.data.length > 0) {
          setInfo(response.data[0]);
          setFormName(response.data[0].form);
        } else {
          console.error("No career data found.");
          setError("Career details not found!");
        }
      } catch (error) {
        console.error(error.message);
        setError("Unable to fetch career details! Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (formName) {
      const fetchForm = async () => {
        try {
          const response = await axios.get(
            `${SERVER_URL}/forms/get/${formName}`
          );
          if (response.data?.forms) {
            setForms(response.data.forms);
          } else {
            console.error("No forms data found for:", formName);
            setError("Form not found!");
          }
        } catch (error) {
          console.error(
            "Fetch Form Error:",
            error.response?.data || error.message
          );
          setError(
            "Unable to fetch career form details! Please try again later."
          );
        }
      };

      fetchForm();
    }
  }, [formName]);

  const handleChangeFormData = (componentId, fieldName, value) => {
    setFormData((prevData) => {
      const currentComponentData = prevData[componentId] || {};
      return {
        ...prevData,
        [componentId]: {
          ...currentComponentData,
          [fieldName]: value,
        },
      };
    });
  };

  const countryValidationRules = {
    "+1": { minLength: 10, maxLength: 10 },
    "+44": { minLength: 11, maxLength: 11 },
    "+91": { minLength: 10, maxLength: 10 },
    "+61": { minLength: 9, maxLength: 9 },
    "+49": { minLength: 10, maxLength: 11 },
    "+33": { minLength: 9, maxLength: 9 },
    "+86": { minLength: 11, maxLength: 11 },
    "+81": { minLength: 10, maxLength: 11 },
    "+55": { minLength: 10, maxLength: 11 },
    "+27": { minLength: 10, maxLength: 10 },
    "+52": { minLength: 10, maxLength: 10 },
    "+7": { minLength: 10, maxLength: 10 },
    "+92": { minLength: 10, maxLength: 10 },
    "+65": { minLength: 8, maxLength: 8 },
    "+39": { minLength: 10, maxLength: 10 },
    "+20": { minLength: 11, maxLength: 11 },
    "+90": { minLength: 10, maxLength: 10 },
    "+54": { minLength: 10, maxLength: 10 },
    "+234": { minLength: 11, maxLength: 11 },
    "+82": { minLength: 10, maxLength: 11 },
  };

  const validateMobileNumber = (number, countryCode) => {
    const { minLength, maxLength } = countryValidationRules[countryCode] || {
      minLength: 8,
      maxLength: 11,
    };
    return number.length >= minLength && number.length <= maxLength;
  };

  const getMinLength = (countryCode) => {
    return countryValidationRules[countryCode]?.minLength || 8;
  };

  const getMaxLength = (countryCode) => {
    return countryValidationRules[countryCode]?.maxLength || 11;
  };

  const countryCodes = [
    "+54",
    "+61",
    "+43",
    "+32",
    "+55",
    "+359",
    "+1-242",
    "+1-876",
    "+1",
    "+971",
    "+44",
    "+91",
    "+33",
    "+49",
    "+34",
    "+39",
    "+81",
    "+7",
    "+86",
    "+20",
    "+63",
    "+90",
    "+351",
    "+48",
    "+41",
    "+46",
    "+45",
    "+64",
    "+27",
    "+82",
    "+66",
    "+55",
    "+65",
    "+32",
    "+58",
    "+30",
    "+34",
    "+56",
    "+45",
    "+27",
  ];

  const getCountryName = (code) => {
    const countryNames = {
      "+54": "Argentina",
      "+61": "Australia",
      "+43": "Austria",
      "+32": "Belgium",
      "+55": "Brazil",
      "+359": "Bulgaria",
      "+1-242": "Bahamas",
      "+1-876": "Jamaica",
      "+1": "USA",
      "+971": "United Arab Emirates",
      "+44": "UK",
      "+91": "India",
      "+33": "France",
      "+49": "Germany",
      "+34": "Spain",
      "+39": "Italy",
      "+81": "Japan",
      "+7": "Russia",
      "+86": "China",
      "+20": "Egypt",
      "+63": "Philippines",
      "+90": "Turkey",
      "+351": "Portugal",
      "+48": "Poland",
      "+41": "Switzerland",
      "+46": "Sweden",
      "+45": "Denmark",
      "+64": "New Zealand",
      "+27": "South Africa",
      "+82": "South Korea",
      "+66": "Thailand",
      "+65": "Singapore",
      "+58": "Venezuela",
      "+30": "Greece",
      "+56": "Chile",
    };

    return countryNames[code] || "Unknown Country";
  };

  const countryNames = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, North",
    "Korea, South",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  const handleSaveFormData = async (e, item) => {
    e.preventDefault();
    try {
      const formResponse = await fetch(`${SERVER_URL}/forms/submit`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          formName: item.formName,
          formData,
        }),
      });

      if (formResponse.status === 201) {
        setFormData({});
      } else {
        const responseData = await formResponse.json();
        alert(`Error: ${responseData.message || "Form submission failed"}`);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("There was an error while submitting the form. Please try again.");
    }
  };

  if (loading) {
    return <div className="career-section1-container">Loading...</div>;
  }

  if (error) {
    return <div className="career-section1-container">{error}</div>;
  }

  if (!info || info.length === 0) {
    return <div className="career-section1-container">No data available.</div>;
  }

  const section = info;

  return (
    <section>
      <div className="career-section1-container">
        <div className="career-section1-details">
          <div className="career-section1-intro">
            <div className="span-shape">
              <div className="shape"></div>
              <span>{section.title || "Default Title"}</span>
            </div>
            <div>
              <h3>{section.heading || "Default Heading"}</h3>
              <p>{section.description}</p>
            </div>
          </div>
          {section.image && (
            <div className="career-section1-image">
              <img
                src={`${SERVER_URL}/${section.image}`}
                alt="Section visual"
              />
            </div>
          )}
        </div>

        <h1>Please submit your details</h1>
        <div className="career-form-container">
          <form method="POST" onSubmit={handleSaveFormData}>
            {forms.length > 0 &&
              forms[0].components.map((component, index) => (
                <div className="selected-form" key={index}>
                  {component.type === "heading" && (
                    <h1 style={{ fontSize: `${forms.headingFontSize}px` }}>
                      {component.heading || "Default Heading"}
                    </h1>
                  )}
                  {component.type === "description" && (
                    <p>{component.description || "Default Description"}</p>
                  )}
                  {component.type === "text" && (
                    <div className="form-group">
                      <input
                        type="text"
                        name="heading"
                        value={formData[component.heading]?.value || ""}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          handleChangeFormData(
                            component.heading,
                            "value",
                            newValue
                          );
                        }}
                        required={component.required}
                      />
                      <label htmlFor="heading">{component.heading}</label>
                    </div>
                  )}
                  {component.type === "textarea" && (
                    <div className="form-group">
                      <textarea
                        rows={3}
                        value={formData[component.heading]?.value || ""}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          handleChangeFormData(
                            component.heading,
                            "value",
                            newValue
                          );
                        }}
                        required={component.required}
                      ></textarea>
                      <label htmlFor="heading">{component.heading}</label>
                    </div>
                  )}
                  {component.type === "number" && (
                    <div className="form-group">
                      <input
                        type="text"
                        name={`number-${component.id}`}
                        value={formData[component.heading]?.value || ""}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) {
                            handleChangeFormData(
                              component.heading,
                              "value",
                              value
                            );
                          }
                        }}
                        maxLength={2}
                      />
                      <label htmlFor="heading">{component.heading}</label>
                    </div>
                  )}
                  {component.type === "dropdown" && (
                    <div className="form-group">
                      <select
                        name={`dropdown-${component.id}`}
                        value={formData[component.heading]?.value || ""}
                        onChange={(e) => {
                          const value = e.target.value;
                          handleChangeFormData(
                            component.heading,
                            "value",
                            value
                          );
                        }}
                        required={component.required}
                      >
                        <option value="">{component.heading}</option>
                        {component.dropdownOptions.map((option, optIndex) => (
                          <option key={optIndex} value={option.text}>
                            {option.text}
                          </option>
                        ))}
                      </select>
                      <label htmlFor={`dropdown-${component.id}`}>
                        {component.heading}
                      </label>
                    </div>
                  )}
                  {component.type === "file" && (
                    <div className="form-group">
                      <input
                        type="file"
                        name="file"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          handleChangeFormData(
                            component.heading,
                            "value",
                            file
                          );
                        }}
                      />
                      {formData[component.heading]?.value && (
                        <p>
                          Selected file:{" "}
                          {formData[component.heading]?.value.name}
                        </p>
                      )}
                      <label htmlFor="file">{component.heading}</label>
                    </div>
                  )}
                  {component.type === "radio" && (
                    <div className="form-group">
                      {component.radioOptions?.map((option) => (
                        <div
                          className="page-option-text-container"
                          key={option.id}
                        >
                          <input
                            type="radio"
                            id={`${component.id}-${option.id}`}
                            name={component.heading}
                            value={option.text}
                            checked={
                              formData[component.heading]?.value === option.text
                            }
                            onChange={(e) => {
                              handleChangeFormData(
                                component.heading,
                                "value",
                                e.target.value
                              );
                            }}
                            required={component.required}
                          />
                          <p>{option.text || "No text provided"}</p>
                        </div>
                      ))}
                      <label htmlFor="">{component.heading}</label>
                    </div>
                  )}
                  {component.type === "date" && (
                    <div className="form-group">
                      <input
                        type="date"
                        name="date"
                        value={formData[component.heading]?.value || ""}
                        onChange={(e) => {
                          const value = e.target.value;
                          handleChangeFormData(
                            component.heading,
                            "value",
                            value
                          );
                        }}
                        placeholder={component.heading}
                        required={component.required}
                      />
                      <label htmlFor="date">{component.heading}</label>
                    </div>
                  )}
                  {component.type === "time" && (
                    <div className="form-group">
                      <input
                        type="time"
                        name="time"
                        value={formData[component.heading]?.value || ""}
                        onChange={(e) => {
                          const value = e.target.value;
                          handleChangeFormData(
                            component.heading,
                            "value",
                            value
                          );
                        }}
                        placeholder={component.heading}
                        required={component.required}
                      />
                      <label htmlFor="time">{component.heading}</label>
                    </div>
                  )}
                  {component.type === "mobile" && (
                    <div className="form-group">
                      <div className="mobile-container">
                        <select
                          name="countryCode"
                          value={formData[component.heading]?.countryCode || ""}
                          onChange={(e) => {
                            const countryCode = e.target.value;
                            handleChangeFormData(
                              component.heading,
                              "countryCode",
                              countryCode
                            );
                          }}
                          required={component.required}
                        >
                          <option value="">Select Country Code</option>
                          {countryCodes.map((code, index) => (
                            <option key={index} value={code}>
                              {code} ({getCountryName(code)})
                            </option>
                          ))}
                        </select>

                        <input
                          type="text"
                          value={formData[component.heading]?.value || ""}
                          maxLength={getMaxLength(
                            formData[component.heading]?.countryCode || ""
                          )}
                          minLength={getMinLength(
                            formData[component.heading]?.countryCode || ""
                          )}
                          onKeyDown={(e) => {
                            if (
                              e.key !== "Backspace" &&
                              e.key !== "Delete" &&
                              e.key !== "ArrowLeft" &&
                              e.key !== "ArrowRight" &&
                              !/^\d$/.test(e.key)
                            ) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            const countryCode =
                              formData[component.heading]?.countryCode;

                            if (/^\d*$/.test(newValue)) {
                              handleChangeFormData(
                                component.heading,
                                "value",
                                newValue
                              );

                              if (
                                countryCode &&
                                countryValidationRules[countryCode]
                              ) {
                                const isValid = validateMobileNumber(
                                  newValue,
                                  countryCode
                                );
                                if (!isValid) {
                                  console.error(
                                    "Invalid mobile number length for the selected country"
                                  );
                                }
                              }
                            }
                          }}
                          placeholder="Enter Mobile Number"
                          required={component.required}
                        />
                      </div>
                      <label htmlFor="countryCode">Country Code</label>
                    </div>
                  )}
                  {component.type === "email" && (
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        value={formData[component.heading]?.value || ""}
                        onChange={(e) => {
                          const emailValue = e.target.value;
                          handleChangeFormData(
                            component.heading,
                            "value",
                            emailValue
                          );
                        }}
                        className={`email-input ${
                          formData[component.heading]?.value &&
                          !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                            formData[component.heading]?.value
                          )
                            ? "invalid"
                            : ""
                        }`}
                        required={component.required}
                      />
                      <label htmlFor="email">{component.heading}</label>
                    </div>
                  )}
                  {component.type === "country" && (
                    <div className="form-group">
                      <select
                        name={`country-${component.id}`}
                        value={
                          formData[component.heading]?.selectedCountry || ""
                        }
                        onChange={(e) => {
                          const value = e.target.value;
                          handleChangeFormData(
                            component.heading,
                            "selectedCountry",
                            value
                          );
                        }}
                        required={component.required}
                      >
                        <option value="">{component.heading}</option>
                        {countryNames.map((country, index) => (
                          <option key={index} value={country}>
                            {country}
                          </option>
                        ))}
                      </select>
                      <label htmlFor={`country-${component.id}`}>
                        {component.heading}
                      </label>
                    </div>
                  )}
                  {component.type === "image" && (
                    <div className="image-show-container">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            const reader = new FileReader();
                            reader.onload = () => {
                              const imageData = reader.result;
                              handleChangeFormData(
                                component.heading,
                                "value",
                                imageData
                              );
                            };
                            reader.readAsDataURL(file);
                          }
                        }}
                        placeholder={component.heading}
                        required={component.required}
                      />
                      {formData[component.heading]?.value && (
                        <img
                          src={formData[component.heading]?.value}
                          alt="Preview"
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                          }}
                        />
                      )}
                    </div>
                  )}
                  {component.type === "button" && (
                    <div className="form-group">
                      <button className="primary-button" type="submit">
                        {forms[0].buttonText}
                      </button>
                    </div>
                  )}
                </div>
              ))}
          </form>
        </div>
      </div>
    </section>
  );
};

export default Section1;
